<!--
 * @Description: 设置处理结果
 * @Author: 琢磨先生
 * @Date: 2024-01-03 10:29:21
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-23 10:19:17
-->
<template>
  <el-dialog
    title="设置结果"
    v-model="visible"
    width="800"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <el-descriptions v-if="item">
      <el-descriptions-item label="系统编号">{{
        item.id
      }}</el-descriptions-item>
      <el-descriptions-item label="报备单号">{{
        item.hNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="公司名称">{{
        item.company
      }}</el-descriptions-item>
      <el-descriptions-item label="带看项目">{{
        item.estateName
      }}</el-descriptions-item>
      <el-descriptions-item label="带看人"
        >{{ item.daikanName }}/{{ item.daikanMobile }}</el-descriptions-item
      >
      <el-descriptions-item label="报备人"
        >{{ item.consumerName }}/{{ item.consumerMobile }}</el-descriptions-item
      >
      <el-descriptions-item label="客户姓名">{{
        item.clientName
      }}</el-descriptions-item>
      <el-descriptions-item label="客户性别">{{
        item.sex
      }}</el-descriptions-item>
      <el-descriptions-item label="客户手机">{{
        item.clientMobile
      }}</el-descriptions-item>
      <el-descriptions-item label="到案人数">{{
        item.dueNum
      }}</el-descriptions-item>
      <el-descriptions-item label="到案时间">{{
        item.dueTime
      }}</el-descriptions-item>
      <el-descriptions-item label="报备备注">{{
        item.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="报备时间">{{
        item.creationTime
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-link type="primary" :underline="false">{{
          item.cur_status_text
        }}</el-link>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="结果" prop="curStatus">
        <el-radio-group v-model="form.curStatus" @change="onStatusChange">
          <el-radio :label="1">已发开发商(结果未知)</el-radio>
          <el-radio :label="2">有效</el-radio>
          <el-radio :label="3">无效</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="说明" prop="resultContent">
        <el-col :span="16">
          <el-input
            v-model="form.resultContent" 
            name="resultContent"
            autocomplete="on"
            placeholder=""
            clearable
          ></el-input
        ></el-col>
      </el-form-item> 
      
    </el-form>

    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" native-type="submit" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      rules: {
        curStatus: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = {
            ids: [this.item.id],
            curStatus: 1,
          };
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClosed() {
      this.$emit("closed");
    },

    /**
     * 结果选择更改
     * @param {*} o
     */
    onStatusChange(o) {
      this.$refs.form.clearValidate();
      this.rules.resultContent = [];
      if (o == 3) {
        this.rules.resultContent = [
          { required: true, message: "请输入", trigger: "blur" },
        ];
      }
    },

    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/report/sent/result", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.visible = false;
                this.$emit("success");
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped></style>
